import React from 'react';
import { css } from '@emotion/react';
import { PropagateLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
`;

function Loading() {
  return (
    <div className="loading">
      <PropagateLoader color="#36D7B7" css={override} size={15} />
    </div>
  );
}

export default Loading;