import React from 'react';
import './TaskCard.css';

const TaskCard = ({ deadline, subject, content, scope, note, url }) => {
  const isDeadlineOverdue = new Date(deadline).setHours(0,0,0,0) < new Date().setHours(0,0,0,0);
  const isDeadlineToday = new Date(deadline).setHours(0,0,0,0) === new Date().setHours(0,0,0,0);
  const deadlineColorClass = isDeadlineOverdue ? 'task-card-header task-card-header--overdue' : isDeadlineToday ? 'task-card-header task-card-header--due-today' : 'task-card-header';
  
  return (
    <div className="task-card">
      <div className={deadlineColorClass}>
        <div className="task-card-deadline">{new Date(deadline).toLocaleDateString('ja-JP')}</div>
        <div className="task-card-subject">{subject}</div>
      </div>
      <div className="task-card-body">
        <div className="task-card-task">{content}</div>
        {scope && <div className="task-card-range">{scope}</div>}
      </div>
      {subject === '模試・検定' && <div className="task-card-footer">
        <div className="task-card-url"><a href={url} target="_blank" rel="noopener noreferrer">お申し込みはこちら</a></div>
      </div>}
      {note && <div className="task-card-footer">
        <div className="task-card-notes">{note}</div>
      </div>}
    </div>
  );
};

export default TaskCard;
