import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import Loading from './components/loading';
import TaskCard from './components/TaskCard';

function App() {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          'https://script.google.com/macros/s/AKfycbwIMr4hnPdsGZLXjZJhprLDLJ8QKqs8DtVBZ82Hol-OdGWopS_B6Y3z00TSPEJKgaJ1/exec'
        );
        setTasks(response.data.map(task => {
          const { deadline, subject, content, scope, note, url } = task;
          if (subject === '模試・検定') {
            return { deadline, subject, content, scope, note, url };
          }
          return { deadline, subject, content, scope, note };
        }));
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTasks();    
  }, []);

  return (
    <div className="app">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="header">
          <h1>Submit Pro for 2-2</h1>
          <p>※課題の内容は変更される可能性があります。</p>
          <p>Note: <a href='https://docs.google.com/spreadsheets/d/e/2PACX-1vT_2KYvJhNvKBcWt8klntI89mCT8qilcZ0t76L99eXD-CqJ5DWBXGfFw9g10kL7ZghlutzFn277Qf8w/pubchart?oid=296882053&format=interactive' target='_blanc' rel='noopener noreferrer'>過去の課題に占める教科の割合のグラフ(試験版)</a>を公開しました</p>
        </div>
      )}
        <div className="task-list">
          {tasks.map((task) => (
            <TaskCard
              key={task.content}
              deadline={task.deadline}
              subject={task.subject}
              content={task.content}
              scope={task.scope}
              note={task.note}
              url={task.url}
            />
          ))}
        </div>
    </div>
  );
}

export default App;
